import WebFont from 'webfontloader';
import { useEffect } from 'react';
import './app.css'

function App() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Abril Fatface']
      }
    });
   }, []);

  return (
    <div className="app">
      <div className='container'>
        <p className="title">(Em) Construção</p>
        <p className='subtext'>Gazeta Experimental</p>
      </div>
    </div>
  );
}

export default App;
